import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

const Home = () => {
  const [codigoUnico, setCodigoUnico] = useState('');
  const [iframeSrc, setIframeSrc] = useState('/code');

  useEffect(() => {
    // Función para generar o recuperar el código único del localStorage
    const generarORecuperarCodigoUnico = () => {
      let codigo = localStorage.getItem('codigoUnico');
      if (!codigo) {
        // Genera un código único si no está configurado
        codigo = generarCodigoUnico();
        localStorage.setItem('codigoUnico', codigo);
      }
      return codigo;
    };

    const generarCodigoUnico = () => {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const longitud = 5;
      let codigo = '';
      for (let i = 0; i < longitud; i++) {
        codigo += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
      }
      return codigo;
    };

    if (!codigoUnico) {
      setCodigoUnico(generarORecuperarCodigoUnico());
    }
    const verificarNuevoLink = async () => {
      try {
        const response = await axios.get(`https://sd.television-librehd.com/nercast/a.php?code=${codigoUnico}`);
        const linkCompartido = response.data;

        if (linkCompartido && iframeSrc !== linkCompartido) {
          setIframeSrc(linkCompartido);
        }
      } catch (error) {
      }
    };

    // Verifica cada segundo
    const intervalo = setInterval(verificarNuevoLink, 1500);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalo);
  }, [codigoUnico, iframeSrc]);


  const [isFullScreen, setIsFullScreen] = useState(false);
  const iframeRef = useRef(null);

  const toggleFullScreen = () => {
    const iframe = iframeRef.current;

    if (!isFullScreen) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen().catch(err => {
          
        });
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen().catch(err => {
          
        });
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen().catch(err => {
          
        });
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(err => {
         
        });
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen().catch(err => {
          
        });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().catch(err => {
          
        });
      }
    }

    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className="mx-auto">

<iframe ref={iframeRef} allowFullScreen sandbox="allow-scripts allow-same-origin" src={iframeSrc} id='iframes' className="w-full m-0 p-0 border-0" allow="encrypted-media *; autoplay" style={{height: "calc(100vh - 70px)"}} referrerpolicy="unsafe-url"></iframe>

      <div className="pl-12 pr-12 bg-base-100 fixed bottom-0 left-0 flex justify-between w-full bg-[#0B0F19] pt-5" style={{height:"68px"}}>
        <div className="items-center">
         <img src='https://i.imgur.com/1EANiMH.png' className='w-9'></img>

          
        </div>
        <div className="flex-1 ml-2">
         

          <ul className="px-1 flex">
          <li className='bg-[#1D283A] text-white outline-none rounded-lg ml-1 pl-3 pr-3 pt-1 pb-1 -mt-0.5 font-semibold'>
              <a>Código: {codigoUnico}</a>
            </li>
            </ul>
        </div>
        <div className="flex-none">
          <ul className="px-1 flex">
            
        
       
            <li
            onClick={toggleFullScreen}
              className="mr-2 pl-0">
         <a className='pl-2 pr-2 flex items-center gap-x-1 place-content-center cursor-pointer hover:text-white/60'>
         <svg  fill="none" className='w-5 h-5 p-0 m-0 mr-1' strokeWidth={1.5} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
</svg>Pantalla completa
         </a>
            </li>
          <div className='text-white outline-none rounded-lg mr-3 border-l-2 ml-2 border-neutral-600'>
            </div>
            <li
             onClick={() => { localStorage.clear(); window.location.href = '/'; }}
             className='pl-2 pr-2 flex items-center gap-x-1 place-content-center cursor-pointer hover:text-white/60'
            >
              <a>Resetear código</a>
            </li>
           
            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
